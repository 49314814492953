const NavRoutes = {
  DashboardOverview: { path: "/dashboard/exchange" },
  TwoFAEmail: { path: "/twofa-email" },
  OnboardingPage: { path: "/" },
  Registration: { path: "/registration" },
  SignUp: { path: "sign-up" },
  VerifyEmail: { path: "verify-email" },
  TwoFactorAuth: { path: "two-factor-auth" },
  TwoFactorPin: { path: "two-factor-pin" },
  SignInUser: { path: "user/sign-in" },

  Login: { path: "/auth" },

  SignIn: { path: "sign-in" },
  ForgotPass: { path: "forgot-pass" },
  ResetPass: { path: "reset-pass" },
  OtpVerification: { path: "otp-verification" },
  AccountSetup: { path: "/account-setup" },
  Wallets: { path: "/dashboard" },
  Dashboard: { path: "buy" },
  TopUp: { path: "exchange" },
  FiatWallet: { path: "history" },
  LoyaltyWallet: { path: "transactions" },
  Account: { path: "/account" },
  SupportHelp: { path: "/support/help" },
  ThriftyWallet: { path: "/redonion" },
  TermsAndConditions: { path: "terms-and-condition" },
  PrivacyPolicy: { path: "privacy-policy" },
  AboutPage: { path: "about" },
  ContactPage: { path: "contact" },
  FAQ: { path: "FAQ" },
  AML: { path: "aml" },
};

export default NavRoutes;
